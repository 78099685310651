/* ------------------------------------------------
Q&A Question Answer Custom Styles
---------------------------------------------- */

.evg__question-answer--content{
  width: 50vw;
}

.evg__pattern__stripes--side{
  background-image: url(/wp-content/uploads/2020/08/stripe-tile_60px_5pct.png);
  background-repeat: repeat;
  padding: 0;
  margin: 0;
}

.evg__qa--wrapper{
  margin: 0;
  padding-left: 100px;
  padding-top: 40px;
  padding-right: 100px;
  padding-bottom: 40px;
}

@media only screen and (max-width: 768px) {

  .evg__qa--wrapper {
    padding: 20px;
  }

  .fl-accordion-button-label {
    padding: 20px;
    margin: 0;
  }
}
