//
// Base Navigation Styles
//====================================
.genesis-nav-menu {
  clear: both;
  line-height: $line-height--smaller;
  width: 100%;

  .menu-item {
    display: block;
    float: none;
    position: relative;

    @media only screen and (min-width: $standard-screen-width) {
      display: inline-block;
    }

    &:focus,
    &:hover {
      position: relative;

      @media only screen and (min-width: $standard-screen-width) {
        position: relative;
      }
    }

    &:hover > .sub-menu {
      display: block;
      left: auto;
      opacity: 1;
    }
  }

  a {
    color: $genesis-nav-menu--font-color;
    font-family: $headings-font;
    text-transform: uppercase;
    display: block;
    font-size: $genesis-nav-menu--font-size;
    font-weight: $font-weight--bold;
    letter-spacing: .1rem;
    outline-offset: -1px;
    padding-bottom: 12px;
    padding-top: 12px;
    text-decoration: none;
    border-top: 2px solid $white;


    &:focus,
    &:hover,
    .current-menu-item > &,
    .sub-menu .current-menu-item > &:focus,
    .sub-menu .current-menu-item > &:hover {
      color: $genesis-nav-menu--font-color-hover;
      border-top: 2px solid $brand__primary--blue;
      text-decoration: none;
    }
  }

  > .menu-bold > a {

    @media only screen and (min-width: $standard-screen-width) {
      font-weight: $font-weight--bold;
    }
  }

  > .menu-highlight > a {

    @media only screen and (min-width: $standard-screen-width) {
      background-color: $menu-highlight__link--background-color;
      border-radius: 3px;
      color: $menu-highlight__link--font-color;
      font-weight: $font-weight--semibold;
      margin-left: 15px;
      padding-left: 20px;
      padding-right: 20px;
    }

    &:focus,
    &:hover {

      @media only screen and (min-width: $standard-screen-width) {
        background-color: $menu-highlight__link--background-color-hover;
      }
    }
  }

  .sub-menu {
    width: 100%;
    clear: both;
    display: none;
    left: -9999px;
    margin: 0;
    opacity: 1;
    padding-left: 15px;
    position: static;
    z-index: 99;

    @media only screen and (min-width: $standard-screen-width) {
      width: auto;
      border-top: 1px solid $lighter-gray;
      opacity: 0;
      padding-left: 0;
      position: absolute;
      transition: opacity 0.4s ease-in-out;
    }


    a {
      width: 100%;
      background-color: $genesis-nav-menu__sub-menu__link--background-color;
      @include rem( font-size, $genesis-nav-menu__sub-menu__link--font-size );
      position: relative;
      word-wrap: break-word;



      &:hover{
       // background-color: $brand__primary--blue;
        border-left: 4px solid $brand__primary--blue;
      }

      @media only screen and (min-width: $standard-screen-width) {
        width: 180px;
        border: $border-width $border-style $border-color-lighter;
        border-top: 0;
        padding-bottom: 15px;
        padding-top: 15px;
      }
    }

    .sub-menu {

      @media only screen and (min-width: $standard-screen-width) {
        margin: -46px 0 0 179px;
      }
    }
  }
}
