//
// Site Header Base Style
//=================================================

.site-header {
  background-color: $site-header--background-color;
  box-shadow: 0 0 $box-shadow-blur-radius rgba(0, 0, 0, $box-shadow-opacity);
  padding: 0 30px;

  @media only screen and (min-width: $standard-screen-width) {

      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 9999;

    .admin-bar & {
      top: 32px;
    }
  }
}

