/* --------------------------
TESTIMONIALS CUSTOM STYLES
------------------------- */

p.testimonial{
  font-family: $serif--font-family;
  letter-spacing: $tracking--small;
  line-height: 4rem;
  color: $white;
  font-size: 3rem !important;


}

.testimonial{
  font-family: $serif--font-family;
  letter-spacing: $tracking--small;
  line-height: 4rem;
  color: $white;
  font-size: 3rem !important;


}

.testimonialSig {
  font-family:$serif--font-family;
  color: $white;
text-align: right;
  font-size: 1.25rem;
&::before {
  content: "— "
}

}

.testimonial--row{
 background-image: url(http://rva-ballet.local/wp-content/uploads/2020/05/Testimonial-QuoteMarks-1.svg);
  background-repeat: no-repeat;
  background-size:50%;
//background-position: 50px 50px;
  background-color: $brand__primary--blue;

}

a.fl-button{
  transition: 0.5s;
}
a.fl-button:hover{
background-color: $brand__primary--blue !important;
  background-image: linear-gradient(#93E0E5, $brand__primary--blue) !important;
  //border-radius:20px !important;
  box-shadow: 2px 2px 6px $light-gray,
  -2px -2px 6px #ffffff;

  transition: 0.5s;
}