/* Responsive Menu
--------------------------------------------- */

.menu .menu-item:focus {
  position: static;
}

.menu .menu-item > a:focus + ul.sub-menu,
.menu .menu-item.sfHover > ul.sub-menu {
  left: auto;
  opacity: 1;
}

.genesis-responsive-menu {
  display: none;
  position: relative;

  @media only screen and (min-width: $standard-screen-width) {
    display: block;
    padding-top: 15px;
  }

  .genesis-nav-menu .menu-item:hover > .sub-menu {
    display: none;
  }
}

.menu-toggle,
.sub-menu-toggle {
  background-color: transparent;
  border-width: 0;
  color: $menu-toggle--font-color;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  visibility: visible;

  @media only screen and (min-width: $standard-screen-width) {
    display: none;
    visibility: hidden;
  }
}

.menu-toggle:focus,
.menu-toggle:hover,
.sub-menu-toggle:focus,
.sub-menu-toggle:hover {
  background-color: transparent;
  border-width: 0;
  color: $menu-toggle--font-color-hover;
}

.menu-toggle {
  float: right;
  line-height: $line-height--big;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 15px 0;
  position: relative;
  z-index: 1000;

  &.activated::before {
    content: "\f335";
  }

  .site-header &::before {
    float: left;
    margin-right: 5px;
    position: relative;
    text-rendering: auto;
    top: 1px;
  }
}

.sub-menu-toggle {
  float: right;
  padding: 9px 10px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;

  .sub-menu & {
    padding: 12px 10px;
  }

  &::before {
    display: inline-block;
    text-rendering: auto;
    transform: rotate(0);
    transition: transform 0.25s ease-in-out;
  }

  &.activated::before {
    transform: rotate(180deg);
  }
}