/* --------------------
TABLES WORD BREAKS
 -------------------- */
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 40px;
  table-layout: fixed;
  width: 100%;
  word-break: normal;

  @media only screen and (min-width: $small-screen-width) {
    table-layout: auto;
    word-break: normal;
  }
}

tbody {
  border-bottom: 1px solid $lighter-gray;
}

td,
th {
  text-align: left;
  vertical-align: top;
}

td {
  border-top: 1px solid $lighter-gray;
  padding: 10px;
  word-wrap: normal;
  word-break: normal;
}

th {
  font-weight: $font-weight--semibold;
  padding: 0 10px 10px;
}

td:first-child,
th:first-child {
  padding-left: 0;
}

td:last-child,
th:last-child {
  padding-right: 0;
}