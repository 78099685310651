//
// Comment Content
//===========================

.comment-content {
  clear: both;

  ul > li {
    list-style-type: disc;
  }
}