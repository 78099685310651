/* Genesis Simple FAQ
--------------------------------------------- */

.gs-faq__question {
  background: transparent;
  border-bottom: 1px solid $lighter-gray;
  color: $gs-faq__question--font-color;
  padding-left: 0;
  padding-right: 0;

  &:focus,
  &:hover {
    background: transparent;
    color: $gs-faq__question--font-color-hover;
  }

  &::after {
    content: "\f132";
    font-family: dashicons; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
    float: right;
  }

  &.gs-faq--expanded::after {
    content: "\f460";
  }
}