//
// MindBody plugin
// ============================

// BOOK button on MindBody widget

.bw-widget button.bw-widget__cta,
.bw-widget a.bw-widget__cta {
  color: $white !important;
  background-color: $brand__primary--blue !important;
  border: 0 !important;
  padding: 10px 20px;
  text-transform: uppercase !important;
  font-weight: bold !important;
  text-decoration: none;

  &:hover{
    background-color: $brand__primary--blue__hover !important;
    color: $white !important;
    text-decoration: none;
  }
}

.bw-session__detail-link,
.bw-session__detail-link-text--closed,
.bw-session__detail-link-text--open {
  background-color: $white !important;
  color: $brand__primary--blue !important;
  text-transform: uppercase !important;
  font-weight: bold !important;

  :hover {
    background-color: $white !important;
  }
}

// From the Adult Division Themer pages CSS module

div.healcode .class_list .class_offered_link, div.healcode .class_list .trainer_teaches_link, div.healcode .trainer_list .class_offered_link, div.healcode .trainer_list .trainer_teaches_link  {
  box-shadow: none !important;
  padding: 10px 20px !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: transat-text;
}

.class_offered_link:hover {
  background-color: $brand__primary--blue__hover;
}

.class_info {
  padding: 10px;
}

.healcode-title-text, .class_name {
  padding: 10px 10px 0 10px !important;
  letter-spacing: 6px;
}

div.healcode div.class_list .class_show {
  margin: 20px 0 !important;
  padding: 20px 50px 40px 30px !important;
  border-radius: 1em !important;
}

// CODE PULLED FROM ADULT CLASSES LAYOUT CSS

//.bw-widget__cta {
//  padding:10px 20px !important;
//  text-align:center;
//  color: white !important;
//}
//
//.bw-widget__cta:hover {
//  text-decoration: none !important;
//  background-color: #05283b !important;
//}