/* ---------------------------------------------
NEON CRM - DONATION FORMS
------------------------------------------------ */

.evg__donation--container {
  background-color: white;
  padding: 20px;
}

#snippet_surveyForm .control_head h1{
  font-size: 2rem;
  text-align: center;
  text-decoration: none !important;
  letter-spacing: .5rem;
}

#snippet_surveyForm .control_button {
  font-family: "proxima nova", sans-serif !important;
  font-size: 1rem !important;
  font-weight: bold;
  color: white !important;
  letter-spacing: .1rem;
  text-transform: uppercase;
}

#snippet_surveyForm .leftLabel {
  font-family: "transat text", sans-serif !important;
  font-size: .9rem !important;
  font-weight: 500;
  text-transform: uppercase;
  color: #000000;
  letter-spacing: 1px;
}

td.control_text {
  font-family: "proxima nova", sans-serif !important;
  font-weight: 700;
  font-size: 20px !important;
  color: #000000 !important;
}

td.control_head {
  text-decoration: none !important;
}
