//
// Sidebar Base Styles
//===================================================

.sidebar {
  @include rem( font-size, $sidebar--font-size );
  line-height: $line-height--medium;

  @media only screen and (min-width: $standard-screen-width) {
    float: right;
    width: 30%;
  }

  .sidebar-content & {

    @media only screen and (min-width: $standard-screen-width) {
      float: left;
    }
  }

  .widget {
    margin-bottom: 40px;
  }

  p {
    margin-bottom: 20px;
  }
}