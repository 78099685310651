/* Headings
--------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headings--font-family;
  line-height: $line-height--small;
  margin: 0 0 20px;
  text-transform: uppercase;
}

h1 {
  @include rem( font-size, $h1--font-size );
  letter-spacing: $tracking--wide;
  font-weight: $font-weight--bold;
}

h2 {
  @include rem( font-size, $h2--font-size );
  color: $brand__primary--blue;
  letter-spacing: $tracking--normal;
  font-weight: $font-weight--bold;
}

h3 {
  @include rem( font-size, $h3--font-size );
  font-weight: $font-weight--bold;
}

h4 {
  @include rem( font-size, $h4--font-size );
  font-weight: $font-weight--bold;
}

.entry-content h3,
.entry-content h4 {
  font-weight: $font-weight--bold;
  letter-spacing: $tracking--normal;
}

.entry-content h4 {
  margin-top: 40px;
}

h5 {
  @include rem( font-size, $h5--font-size );
  font-weight: $font-weight--semibold;
}

h6 {
  @include rem( font-size, $h6--font-size );
  font-weight: $font-weight--semibold;
}

// ------ heading-ish -------------- //

.h1-ish,
.h2-ish,
.h3-ish,
.h4-ish,
.h5-ish,
.h6-ish {
  font-family: $headings--font-family;
  line-height: $line-height--small;
  margin: 0 0 20px;
  text-transform: uppercase;
}

.h1-ish {
  @include rem( font-size, $h1--font-size );
  letter-spacing: $tracking--wide;
  font-weight: $font-weight--bold;
}

.h2-ish {
  @include rem( font-size, $h2--font-size );
  color: $brand__primary--blue;
  letter-spacing: $tracking--normal;
  font-weight: $font-weight--bold;
}

.h3-ish {
  @include rem( font-size, $h3--font-size );
  font-weight: $font-weight--bold;
}

.h4-ish {
  @include rem( font-size, $h4--font-size );
  font-weight: $font-weight--bold;
}

.entry-content .h3-ish,
.entry-content .h4-ish {
  font-weight: $font-weight--bold;
  letter-spacing: $tracking--normal;
}

.entry-content .h4-ish {
  margin-top: 40px;
}

.h5-ish {
  @include rem( font-size, $h5--font-size );
  font-weight: $font-weight--semibold;
}

.h6-ish {
  @include rem( font-size, $h6--font-size );
  font-weight: $font-weight--semibold;
}