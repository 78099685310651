/* Float Clearing
--------------------------------------------- */

.author-box::before,
.clearfix::before,
.entry::before,
.entry-content::before,
.footer-widgets::before,
.nav-primary::before,
.nav-secondary::before,
.pagination::before,
.site-container::before,
.site-footer::before,
.site-header::before,
.site-inner::before,
.widget::before,
.wrap::before {
  content: " ";
  display: table;
}

.author-box::after,
.clearfix::after,
.entry::after,
.entry-content::after,
.footer-widgets::after,
.nav-primary::after,
.nav-secondary::after,
.pagination::after,
.site-container::after,
.site-footer::after,
.site-header::after,
.site-inner::after,
.widget::after,
.wrap::after {
  clear: both;
  content: " ";
  display: table;
}