/* Genesis eNews Extended
--------------------------------------------- */

.enews {

  .after-entry & {
    text-align: center;
    padding: 10px;
  }

  .sidebar & {
    background-color: $enews__sidebar--background-color;
    padding: 30px;
  }
}

.enews-widget {

  input {
    @include rem( font-size, $enews-widget__input--font-size );
    margin-bottom: 10px;
  }

  .after-entry & input {
    text-align: center;
  }

  input[type="submit"] {
    margin: 0;
    width: 100%;
  }
}

.enews form + p {
  margin-top: 20px;
}