/* Avatar
--------------------------------------------- */

.avatar {
  border-radius: 50%;
  float: left;

  .author-box &,
  .alignleft & {
    margin-right: 20px;
  }

  .alignright & {
    margin-left: 20px;
  }

  .comment & {
    margin: 0 15px 20px 0;
  }
}