/* --------------------------
COMPANY CUSTOM STYLES
------------------------- */


.evg__dancer--container {
  display: flex;
  object-fit: cover;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0;
  align-content: center;
  justify-content: center;

 .evg__container--bottom--overlay {
    position: absolute;
    bottom: 0;
    z-index: 1000;

  }
}

.evg__dancer--items {
  display: flex;
  flex-grow: 1;
  width: 350px;
  background-color: $white;
  padding: 1em;
  margin: 1em;
}

.evg__dancer--items--pic {
  display: flex;
  width: 25vw;
  flex-grow: 1;
  padding: 0;
  margin: 0px;
}

.evg__dancer--items--how {
  display: flex;
  background-color: #F9F9F9;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0;
}

.evg__dancer--content--how {
  max-width: 500px;
  padding: 50px;
  flex-grow: 1;
  }

.evg__dancer--meta {
  margin: 0 0 10px 0;
}

.evg__wtf {
  display: flex;
justify-content: center;
}
.evg_icon__rb--blue {
  width: 25px;}



  //Dancer post grid blocks

  .evg__dancer--name {
    padding: 0 0 10px 0;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: $font-weight--bold;
    letter-spacing: .25rem;

  }

  .uabb-blog-post-content {
    padding: 0 !important;
    box-shadow: 7px 7px 24px #ededed,
    -7px -7px 24px #ffffff;
  }

  main{
    box-shadow: 0 2rem 4rem #000;
  }

