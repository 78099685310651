//
// Comment Content
//===========================

.comment-list {
  li {
    padding: 40px 0 0 30px;
  }

    .depth-1 {
      padding-left: 0;
  }
}