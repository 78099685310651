/* Lists
--------------------------------------------- */

ol,
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}