/* Site Footer
---------------------------------------------------------------------------- */

.site-footer {
  background-color: $site-footer--background-color;
  border-top: 1px solid $site-footer--border-color;
  @include rem( font-size, $site-footer--font-size );
  line-height: $line-height--medium;
  padding: 30px;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}

/*
footer {
  position: sticky;
  bottom: 0;
  z-index: -1000;
}
*/
