//
// Comments Base Stylingss
//=====================================

.comment-respond,
.entry-comments,
.entry-pings {
  margin-bottom: 40px;

  @media only screen and (min-width: $standard-screen-width) {
    margin-bottom: 60px;
  }
}

.entry-pings .reply {
  display: none;
}

.comment-form-cookies-consent label {
  display: inline;
  padding-left: 10px;
}
