/* --------------------------
VIDEO PLAYER CUSTOM STYLES
------------------------- */
/*
.evg__video--player{
  box-shadow:  20px 20px 60px #70b1b8,
  -20px -20px 60px #98effa;
}
*/
.evg__video--border__narrow {
  border-right: 20px solid white;
  border-left: 20px solid white;
}