//
// Calendar Widget
//===========================

.widget_calendar {
  table {
    width: 100%;
  }

  td,
  th {
    text-align: center;
  }
}