/* -------------------------------------------------------
// Events Calendar - Modern Tribe
------------------------------------------------------- */

.tribe-events .tribe-events-calendar-month__day--current .tribe-events-calendar-month__day-date {
  color: $brand__primary--blue;
}

.tribe-events .tribe-events-c-ical__link {
  color: $brand__primary--blue;
  border: 1px solid $brand__primary--blue;

  &:hover {
    background-color: $brand__primary--blue;
  }
  &::before{
    background-image: url("/wp-content/uploads/2020/04/plus.svg");
  }

}

a.tribe-events-pro-week-grid__event-tooltip-title-link {
  font-family: $headings--font-family;
  text-transform: uppercase;
  letter-spacing: $tracking--small;
  color: $brand__primary--blue;
}

.tribe-events-divider{
  letter-spacing: $tracking--wide;
  color: $brand__primary--blue;
}

.event-is-recurring{
  font-size: 1rem;
}

.fl-module .recurringinfo {
  display: visible !important;
}

.tribe-events-calendar-month__multiday-event-bar{
  background-color: $brand__primary--blue !important;
}

.tribe-events-c-breadcrumbs__list-item-text,
.tribe-events-pro-week-grid__event-title,
.tribe-events-calendar-day__event-title-link,
.tribe-events-pro-photo__event-title-link,
.tribe-events-pro-map__event-title {
  font-family: $headings--font-family !important;
  font-weight: $font-weight--bold !important;
  text-transform: uppercase;
  letter-spacing: $tracking--small;
  color: $brand__primary--blue !important;
}

.tribe-common-h3 {
  color: $brand__primary--blue !important;
}


// TO CHANGE COLOR OF CALENDAR DATE NUMBERS
//.tribe-events-calendar-month__day-date-daynum {
//  color: $brand__primary--blue;
//}

/* We'll need this to contain the date tag when we set it to absolute positioning */
.tribe-events-pro .tribe-events-pro-photo__event-details-wrapper {
  position: relative;
}

/* Re-position the date tag and give it a background */
.tribe-events-pro .tribe-events-pro-photo__event-date-tag {
  background: $brand__primary--blue;
  padding: 5px;
  position: absolute;
  right: 0;
  top: -2rem;
  width: 60px;
  border-radius: 50%;
}

/* Re-color the date day-number */
.tribe-events-pro .tribe-events-pro-photo__event-date-tag-daynum {
color: $white
}

/* Updates the color of the month name */
.tribe-events-pro .tribe-events-pro-photo__event-date-tag-month {
  color: #00293D;
  margin-top: 8px;
  line-height: .8;
  margin-bottom: -4px;
}

/* Headline above Events Calendar */
.tribe-events-before-html h2{
  margin-bottom: 20px;
  font-weight: $font-weight--bold;
}

.tribe-common--breakpoint-medium.tribe-events .tribe-events-l-container{
  padding-top: 0;
}
/* Establish the height to make it round

 */
.tribe-events-pro .tribe-events-pro-photo__event-date-tag-datetime{
  height: 50px;
}

.tribe-events .tribe-events-c-search__input-control--keyword-focus .tribe-events-c-search__input, .tribe-events .tribe-events-c-search__input-control--keyword .tribe-events-c-search__input:focus{
  background-image: url("http://rva-ballet.local/wp-content/uploads/2020/04/magnifying-glass-SKETCH.svg")
}

/* pre-fixing the Events on the Performance page to indicate Start Date */

.fl-post-grid-event-calendar-date::before {
  content: "Begins ";
}

.fl-post-grid-event-calendar-venue::before {
  content: "Venue: "
}

.tribe-events-pro .tribe-events-pro-map__event-card-wrapper--active .tribe-events-pro-map__event-card-button {
  border-color: $brand__primary--blue;
}