/* --------------------------
FACULTY - STAFF CUSTOM STYLES
------------------------- */

h3.evg__card__faculty--name{
  text-transform: uppercase;
  color: $brand__primary--blue;
  font-weight: $font-weight--bold;
  font-size: 1rem;
  text-align: center;
  margin: 0;
}

.fl-post-more-link{
  position: absolute;
  bottom:0;
}

/* --- redundant ----
.evg__card__faculty--title{
  text-align: center;
  margin: 0;
}
*/
.evg__card__faculty--wrapper {
  padding: 0 !important;
  border: none !important;
  box-shadow: 7px 7px 24px #ededed,
  -7px -7px 24px #ffffff;
}

// Faculty Staff post grid blocks

h3.evg__faculty__themer--name {
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: $font-weight--bold;
  color: $brand__primary--blue;
  letter-spacing: .25rem;

}

.evg__card__faculty--title,
.evg__faculty--themer--title {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: $tracking--small;
  font-size: .75rem;
  margin: 10px 0 5px 0;
}