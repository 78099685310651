/* Widget Title
----------------------------------------------------------------------- */

.sidebar .widget-title a {
  color: $widget-title_font-color;
  text-decoration: none;
}

.widget-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}