/* Footer Menu
--------------------------------------------- */

.nav-secondary {
  margin-top: 10px;

  .genesis-nav-menu {
    line-height: $line-height--medium;
  }

  .menu-item {
    display: inline-block;
  }

  a {
    margin-left: 10px;
    margin-right: 10px;
    padding: 0;
  }
}

.fl-rich-text .evg__footer--text,
.fl-rich-text .evg__footer--text a{
  color: $brand__primary--blue__hover !important;
}