//
// Navigation
//===========================

$nav__font-size: 16;
$primary-nav__font-size: 14;
$sub-menu__font-size: 14;

// Base Fonts
//$html--font-size: 62.5%;

$base-font: "proxima-nova", sans-serif;
$headings-font: "transat-text", sans-serif;
$serif-font:"freight-display-pro", serif;

// Font families
$body--font-family: $base-font;
$headings--font-family: $headings-font;
$serif--font-family: $serif-font;

// Font weights
$font-weight--light: 300;
$font-weight--regular: 400;
$font-weight--semibold: 600;
$font-weight--bold: 700;
$font-weight--black: 900;

//
// Line heights
//=============================
$line-height--big: 20px;
$line-height--large: 2;
$line-height--normal: 1.625;
$line-height--medium: 1.5;
$line-height--small: 1.2;
$line-height--smaller: 1;

//
// Font Tracking
//==============================
$tracking--xsmall: .05rem;
$tracking--small: .1rem;
$tracking--normal: .2rem;
$tracking--medium: .4rem;
$tracking--wide: .75rem;
$tracking--extra-wide: 1.25rem;

//
// Font sizes
//================================================

$base--font-size: 20px;
$base--font-size--small-screen: 18px;
$base__div--font-size: 2rem;
$base__div--font-size--small-screen: 1.8rem;
$base--line-height: 1.625;

$body--font-size: 16px;
$h1--font-size: 52px;
$h2--font-size: 42px;
$h3--font-size: 36px;
$h4--font-size: 28px;
$h5--font-size: 24px;
$h6--font-size: 18px;
$button--font-size: 16px;
$archive-author-box__entry-title--font-size: 20px;
$breadcrumb--font-size: 16px;
$entry-title--font-size: 30px;
$featured-content__entry-title--font-size: 16px;
$enews-widget__input--font-size: 16px;
$wpforms-widget__field-sublabel--font-size: 14px;
$site-title--font-size: 20px;
$genesis-nav-menu--font-size: 12px;
$genesis-nav-menu__sub-menu__link--font-size: 14px;
$sidebar--font-size: 16px;
$entry-content__caption--font-size: 14px;
$entry-meta--font-size: 16px;
$entry-meta__footer--font-size: 16px;
$entry-meta__header--font-size: 16px;
$site-footer--font-size: 15px;
$forms--font-size: 18px;
$widget-title--font-size: 18px;
$archive-pagination--font-size: 16px;
$after-entry__breadcrumb--font-size: 16px;
$event__performance__post--title: 22px;
$mousetype: 10px;