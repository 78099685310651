/* --------------------------
COMMUNITY CUSTOM STYLES
------------------------- */

/*
.evg__background--pattern__mim{
  background-image: url(/wp-content/uploads/2020/06/evg_streamline-icon-phone-circle@20x20.svg);
  background-repeat: repeat-x;
  z-index: 1000;
}
*/

.evg__mim--container {
  display: flex;
  object-fit: cover;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0;
  align-content: center;
  justify-content: center;

}

.evg__container--bottom--overlay {
  position: absolute;
  bottom: 0;
  z-index: 1000;

}

#evg__block{
  width:500px;
}


.evg__mim--items {
  display: flex;
  flex-grow: 1;
  width: 350px;
  padding: 1em;
  margin: 1em;
}

.evg__mim--items--pic {
  display: flex;
  width: 20vw;
  flex-grow: 1;
  padding: 0;
  margin: 0px;

}

.evg__mim--items--how {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0;

}

.evg__dancer--content--how {
  max-width: 500px;
  padding: 50px;
  flex-grow: 1;
}

.evg__dancer--meta {
  margin: 0 0 10px 0;
}

.evg__container--bottom--overlay__repeat{
  height: 100px;
  background-image: url(/wp-content/uploads/2020/06/MIM_line-point.png);
  background-repeat: repeat-x;
  z-index: 1000;
}

.evg__full--bkgd {
  background: url(/wp-content/uploads/2020/12/MIM_line-point_bkgd.png) no-repeat center center fixed;
  background-size: cover;
}
