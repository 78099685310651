/* Forms
--------------------------------------------- */

input,
select,
textarea {
  background-color: $forms__background-color;
  border: $border-width $border-style $border-color-light;
  color: $forms--font-color;
  @include rem( font-size, $forms--font-size );
  font-weight: $font-weight--regular;
  padding: 15px;
  width: 100%;
}

input:focus,
textarea:focus {
  border: $border-width $border-style $border-color-med-light;
  outline: none;
}

input[type="checkbox"],
input[type="image"],
input[type="radio"] {
  width: auto;
}

::-ms-input-placeholder {
  color: $dark-gray;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $dark-gray;
  opacity: 1;
}

::placeholder {
  color: $dark-gray;
  opacity: 1;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"],
.site-container div.wpforms-container-full .wpforms-form input[type="submit"],
.site-container div.wpforms-container-full .wpforms-form button[type="submit"],
.button {
  background-color: $brand__primary--blue;
  border: 0;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-family: $headings--font-family;
  @include rem( font-size, $button--font-size );
  font-weight: $font-weight--semibold;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  width: auto;
}

button:focus,
button:hover,
input[type="button"]:focus,
input[type="button"]:hover,
input[type="reset"]:focus,
input[type="reset"]:hover,
input[type="submit"]:focus,
input[type="submit"]:hover,
.site-container div.wpforms-container-full .wpforms-form input[type="submit"]:focus,
.site-container div.wpforms-container-full .wpforms-form input[type="submit"]:hover,
.site-container div.wpforms-container-full .wpforms-form button[type="submit"]:focus,
.site-container div.wpforms-container-full .wpforms-form button[type="submit"]:hover,
.button:focus,
.button:hover {
  background-color: $brand__primary--blue__hover;
  border-width: 0;
  color: $brand__primary--blue;
}

.button {
  display: inline-block;

  .entry-content &:focus,
  .entry-content &:hover {
    color: $white;
  }
}

.site-container button:disabled,
.site-container button:disabled:hover,
.site-container input:disabled,
.site-container input:disabled:hover,
.site-container input[type="button"]:disabled,
.site-container input[type="button"]:disabled:hover,
.site-container input[type="reset"]:disabled,
.site-container input[type="reset"]:disabled:hover,
.site-container input[type="submit"]:disabled,
.site-container input[type="submit"]:disabled:hover {
  background-color: $disabled--background-color;
  border-width: 0;
  color: $disabled--font-color;
  cursor: not-allowed;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button {
  display: none;
}
