/* Content Area
---------------------------------------------------------------------------- */

/* Content
--------------------------------------------- */

.content {

  @media only screen and (min-width: $standard-screen-width) {
    float: left;
    width: 65%;
  }

  .sidebar-content & {

    @media only screen and (min-width: $standard-screen-width) {
      float: right;
    }
  }

  .full-width-content & {

    @media only screen and (min-width: $standard-screen-width) {
      float: none;
      margin-left: auto;
      margin-right: auto;
    }
  }
}