//-----------------------------------
// AOS - ANIMATION ON SCROLL LIBRARY
//-----------------------------------

[data-aos="evg__aos--bkgcolor"] {
  background: $lightest-gray;
  transition-property: background;
  &.aos-animate {
    background: $white;
  }
}

[data-aos="evg__aos--shadow"] {
  box-shadow: 7px 7px 24px red, -7px -7px 24px blue;
  transition-property: all;
  &.aos-animate {
    box-shadow: 7px 7px 24px #ededed, -7px -7px 24px #ffffff;
  }
}

[data-aos^=hide][data-aos^=hide] {
  opacity: 1;
  transition-property: opacity, transform
}

[data-aos^=hide][data-aos^=hide].aos-animate {
  opacity: 0;
  transform: translate(0)
}

[data-aos="fade-out"] {
  opacity: 1;
  transition-property: opacity;
  &.aos-animate {
    opacity: 0;
  }
}