/* Titles
--------------------------------------------- */

.archive-description .entry-title,
.archive-title,
.author-box-title {
  @include rem( font-size, $archive-author-box__entry-title--font-size );
  font-weight: $font-weight--semibold;
  margin-bottom: 10px;
}

.entry-title {
  @include rem( font-size, $entry-title--font-size );
  margin-bottom: 10px;

  a {
    color: $entry-title__link--font-color;
    text-decoration: none;

    &:focus,
    &:hover {
      color: $entry-title__link--font-color-hover;
    }
  }
}

.sidebar .widget-title,
.sidebar .widget-title a {
  color: $sidebar__widget-title--font-color;
  text-decoration: none;
}

.widget-title {
  @include rem( font-size, $widget-title--font-size );
  font-weight: $font-weight--semibold;
  margin-bottom: 20px;
}
