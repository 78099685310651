/* --------------------------
Silhouette Headers - CUSTOM STYLES
------------------------- */


.evg__silo-header--container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 0;
  align-content: center;
  justify-content: center;

  .evg__silo-header--bottom--overlay {
    position: absolute;
    bottom: 0;
    z-index: 1000;

  }
}




.evg__silo-header--items {
  display: flex;
  flex-wrap:wrap;
  width: 50vw;
  background-color: #F9F9F9;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0;
}


.evg__silo-header--content--how {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 50px;
  flex-grow: 1;
}

.evg__silo-header--items--pic {
  padding: 0;
  margin: 0px;
}


.evg__header__text {
  text-align: center;
}

.evg__button--support {
  background-color: $brand__primary--blue;
  color: $white !important;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;
  text-align: center;

}


/* --------------------------
SLIDESHOW HEADER AND STATIC HEADERS - CUSTOM STYLES
------------------------- */

.evg__slideshow-grid--container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 90vh;
  background-color: #F9F9F9;
  object-fit: cover;
}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 667px) {
  .evg__slideshow-grid--container {
    object-fit: cover;
  }

  .evg__slideshow-grid--items{
    -ms-grid-column-span: 3;
    grid-column: span 3;
  }

  .evg__slideshow-grid--items-b{
    -ms-grid-column-span: 3;
    grid-column: span 3;
  }
}

.evg__slideshow-grid--items{
  grid-column: span 1;
}

.evg__slideshow-grid--items-b{
  grid-column: span 2;
}

.evg__slideshow--logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
}

#evg__logo {
  width: 350px;
  padding-bottom: 40px;
}

#evg__header--logo  {
  width: 350px;
  padding-bottom: 40px;
}

.evg__slideshow--pics {
  position: relative;
  height: 100%;
}

// SILO ARE SILHOUETTES ON PAGES OTHER THAN HOME - NO SLIDESHOW
#silo {
  height:90vh;
  position: relative;
  overflow: hidden;
}

#silo img {
  position: absolute;
  top:0;
  overflow: hidden;
}

#silo {
  height:90vh;
  position: relative;
  overflow: hidden;
}

// STAGE ARE SILHOUETTES ON HOME PAGE - SLIDESHOW

#stage {
  height:90vh;
  position: relative;
  overflow: hidden;
}
#stage img {
  position: absolute;
  top:0;
  overflow: hidden;

}

#stage img:nth-of-type(1) {
  -webkit-animation-name: fader;
  animation-name: fader;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  z-index: 20;
}

#stage img:nth-of-type(2) {
  z-index: 10;
}

#stage img:nth-of-type(n+3) {
  display: none;
}

@keyframes fader {
  from { opacity: 1.0; }
  to   { opacity: 0.0; }
}


// COMMUNITY ENGAGEMENT HEADER

// MOBILE STYLES
@media only screen
and (min-device-width: 300px)
and (max-device-width: 812px) {

  .evg__pattern--overlay__stripes {
    background-image: url(/wp-content/uploads/2020/08/rb_pattern_stripey-small.svg);
    height: 20px;
  }

  .evg__text--header {
    font-size: 36px;
    letter-spacing: 1px;
    grid-column: span 3;
  }

  #evg__community--header {
    font-size: 36px;



  }
}
