//
// Widget Base Stylings
//=====================================

.widget {
  margin-bottom: 40px;

  p:last-child,
  ul > li:last-of-type {
    margin-bottom: 0;
  }

  ul > li {
    margin-bottom: 10px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  ol > li {
    list-style-position: inside;
    list-style-type: decimal;
    padding-left: 20px;
    text-indent: -20px;
  }

  li li {
    border: 0;
    margin: 0 0 0 30px;
    padding: 0;
  }
}