/* Featured Content
--------------------------------------------- */

.featured-content {
  .entry {
    border-bottom: 1px solid $lighter-gray;
    margin-bottom: 20px;
  }

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
  }
}
  .entry-title {
    @include rem( font-size, $featured-content__entry-title--font-size );
    margin-bottom: 5px;
    margin-top: 10px;
  }
