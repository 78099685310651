/* ------------------------------------------
Atomic Blocks - Custom Styles
--------------------------------------------- */
.ab-block-accordion .ab-accordion-title{
  background-color: $brand__primary--blue !important;
  color: white;
  font-family: $headings-font;
  font-weight: $font-weight--bold;
  letter-spacing: $tracking--small;
  text-transform: uppercase;
}

.ab-block-accordion .ab-accordion-text {
  background-color: $lightest-gray;
  border: 1px solid $light-gray;
  padding: 40px;
}

.ab-accordion-text{
  padding: 40px !important;
}