/* Block Quote
--------------------------------------------- */

blockquote,
cite,
em,
i {
  font-style: italic;
}

mark {
  background: $mark--background-color;
  color: $mark--font-color;
}

blockquote {
  margin: 30px;
}
