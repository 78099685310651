/* --------------------------
SCHOOL POST THEMER - CUSTOM STYLES
------------------------- */

.evg__school--header{

}
.fl-post-carousel-title {
font-size: 1rem !important;
}

.fl-post-carousel-text{
padding-top: 10px !important;
}

.fl-post-carousel-more{
  font-size: .8rem;
  position: absolute;
  bottom: 10px;

}

.fl-post-carousel-image:hover{

}

.fl-post-carousel-post {
 border: 0 solid #ffffff !important;

   // box-shadow: 7px 7px 24px #ededed,
  //  -7px -7px 24px #ffffff;
}

.fl-post-excerpt{
padding-bottom: 40px;
}

.evg__button__school--posts a{
  padding: 10px 40px 10px 40px;
  color: $white;
  background-color: $brand__primary--blue;
  border-radius: 4px;
  margin: 0 auto;

  &:hover{
    background-color: $brand__primary--blue__hover;
  }
}


.evg__age__school--posts {
text-align: center;
}

.fl-post-grid-post{
}

.evg__school--posts{
  border: none !important;
  border-radius: .4rem;
  box-shadow: 0 0.5rem 0.5rem rgba(13,21,29,.05);
 // box-shadow: 1px 1px 2px 3px #f9f9f9;

  transition: ease .4s;
}

.evg__school--posts:hover{
  box-shadow: 2 2 15px rgba(33, 33, 33, .5) !important;

  // box-shadow:  8px 8px 16px #e3e3e3,
 // -8px -8px 16px #ffffff;
  //box-shadow: 2px 2px 3px 4px #f2f2f2;
  transition: ease .4s;

}


