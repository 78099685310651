//
// Base Colors
//=========================
// $brand__primary--blue: #84D0D9;
$brand__primary--blue: #63C4D0;
// $brand__primary--blue__hover: #04283B;
$brand__primary--blue__hover: #005773;
$brand__secondary--black: #000000;
$brand__secondary--gray: #8A8A8D;
$brand__secondary--orange: #E16740;
$blue: $brand__primary--blue;
$white: #fff;
$black: #000;
$dark-gray: #333;
$med-gray: #777;
$med-light-gray: #999;
$light-gray: #ddd;
$lighter-gray: #eee;
$lightest-gray: #f9f9f9;
$transparent: transparent;

$base__color: #333;
$link__color: #0073e5;
$link__hover-color: #333;
$body__background-color: #eee;

//
// Navigation Colors
//======================================

$nav--link-color: $brand__secondary--orange;
$nav--hover-color: $brand__primary--blue__hover;
$primary-nav__background-color: $white;
$primary-nav__link-color: $brand__primary--blue__hover;

$secondary-nav--background-color: $white;
$secondary-nav--link-color: $nav--link-color;

$footer-widgets--background-color: $white;

$sub-menu--background-color: $white;
$sub-menu--link-background-color: $white;

$menu-toggle--background-color: $transparent;
$menu-highlight__link--font-color: $white;
$menu-highlight__link--background-color: $dark-gray;
$menu-highlight__link--background-color-hover: $blue;

// Font colors
$body--font-color: $dark-gray;
$link--font-color: $blue;
$link--font-color-hover: $dark-gray;
$mark--font-color: $dark-gray;
$button--font-color: $brand__primary--blue__hover;
$button--font-color-hover: $white;
$entry-title__link--font-color: $dark-gray;
$entry-title__link--font-color-hover: $blue;
$site-title__link--font-color: $dark-gray;
$genesis-nav-menu--font-color: $brand__primary--blue__hover;
$genesis-nav-menu--font-color-hover: $brand__primary--blue__hover;
$menu-toggle--font-color: $dark-gray;
$menu-toggle--font-color-hover: $blue;
$menu-highlight__link--font-color: $white;
$sidebar__widget-title--font-color: $dark-gray;
$forms--font-color: $dark-gray;
$archive-pagination--font-color: $dark-gray;
$archive-pagination--font-color-hover: $white;
$gs-faq__question--font-color: $dark-gray;
$gs-faq__question--font-color-hover: $blue;
$disabled--font-color: $med-gray;
$widget-title_font-color: $dark-gray;

// Background colors
$body--background-color: $white;
$site-header--background-color: $white;
$site-footer--background-color: $white;
$mark--background-color: $blue;
$button--background-color: $brand__primary--blue;
$button--background-color-hover: $brand__primary--blue__hover;
$genesis-nav-menu__sub-menu__link--background-color: $white;
$genesis-nav-menu__sub-menu__link--background-color__hover: $lighter-gray;
$footer-widgets--background-color: $white;
$footer-widgets--border-color: $lighter-gray;
$menu-highlight__link--background-color: $dark-gray;
$menu-highlight__link--background-color-hover: $blue;
$forms__background-color: $white;
$sticky--background-color: $lightest-gray;
$author-box--background-color: $lighter-gray;
$code--background-color: $lightest-gray;
$archive-pagination--background-color: $lightest-gray;
$archive-pagination--background-color-hover: $dark-gray;
$enews__sidebar--background-color: $lightest-gray;
$disabled--background-color: $lighter-gray;
