/*
// Beaver Builder - Custom fl- styles
--------------------------------------------- */

.fl-builder-content a.fl-button,
.fl-builder-content a.fl-button:visited {
  background: $brand__primary--blue !important;
  border: none !important;
  //color: $white !important;
  font-family: $headings--font-family !important;
  font-weight: $font-weight--semibold !important;
  &:hover{
    background: $brand__primary--blue__hover !important;
    color: $brand__primary--blue !important;
  }
}
.fl-builder-content a.fl-button *,
.fl-builder-content a.fl-button:visited * {
  color: $white !important;

  &:hover{
    color: $brand__primary--blue !important;
  }
}

a.fl-post-feed-more {
  font-family: $headings--font-family !important;
  font-weight: $font-weight--semibold !important;
}
a.fl-post-feed-more:hover {
  color: $white;
  background-color: $brand__primary--blue__hover;
}

/* -----------------------------------------
PERFORMANCE BUTTONS
----------------------------------------- */

.fl-post-feed-more {
  color: #ffffff;
  text-transform: uppercase;
  font-family: "transat text", sans-serif;
  font-weight: 700;
  font-size: 16px;
  transition: all 0.5s ease;
  background-color: #63C4D0;
  padding: 14px;
  border-radius: 4px;
  margin-bottom: 40px;
  letter-spacing: 1.4px;
}

.fl-post-feed-more:hover {
  background-color: #005670;
  color: #63C4D0 !important;

}

a.fl-post-grid-more {
  text-align: center;
  font-weight: $font-weight--bold;
  letter-spacing: $tracking--small;
  color: $button--font-color;
  background-color: $brand__primary--blue;
  padding: 10px;
  border-radius: 4px;
  width: 150px;
  transition: .3s;
}

a.fl-post-grid-more:hover {
  background-color: $brand__primary--blue__hover;
}

a.fl-tabs-label {
  background-color:$brand__primary--blue;
  color:$white;
  font-weight: $font-weight--bold;
}

a.fl-tab-active {
  color:$brand__primary--blue;
}

.evg__z-index{
  z-index: 1000;
}

div.fl-post-excerpt{
  padding-bottom: 0;
  margin-bottom: 0;
}

.fl-post-excerpt h6{
  font-size: .9rem;
  text-align: center;
  margin: 0;
}

h2.fl-post-title{
  text-align: center;
}

// Headings for the callout cards on home page

.fl-callout-title-text,
.fl-cta-title,
.fl-post-title a{
  color: $brand-_primary--blue !important;
  transition: color 0.5s ease;
  &:hover{
    color: $brand__primary--blue__hover !important;

  }
}

/* ---------------------------------
BB AccordiOn Module Styles
------------------------------------ */

// ACCORDION TEXT AND COLOR ADJUST

.fl-accordion-button-icon {
  font-size: 1.5rem;
  color: $white;
}

.fl-accordion-button {
  transition: background-color .5s ease;
  background-color: $brand__primary--blue;

  &:hover {
    background-color: $brand__primary--blue__hover;
    color: $brand__primary--blue !important;

  }
}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 768px) {
  .fl-accordion-button{
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.fl-accordion-button-label {
  font-family: $base-font;
  font-weight: $font-weight--bold;
  letter-spacing: $tracking--small;
  color: $white;

  &:hover{
    background-color: $brand__primary--blue__hover;
    color: $brand__primary--blue;
  }
}

// MOBILE MARGINS ON ACCORDIONS

.fl-accordion-item {
  background-color: $white;
}

.fl-accordion-content {
  //margin: 40px;
  padding: 40px !important;
  // background-color: mediumvioletred;
}

// FROM DANCER THEMER - REMOVES WHITE SPACE FROM ACCORDION EDGES
//.fl-accordion-medium .fl-accordion-content {
//  padding: 0 !important;
//}


// REDUNDANT CODE TO THAT BELOW?
//@media only screen
//and (min-device-width: 300px)
//and (max-device-width: 768px)
//{
//  .fl-accordion-medium.fl-accordion-content {
//    padding: 20px !important;
//  }
//}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 768px) {
.fl-accordion-content{
  background-color: $white;
  padding:0 !important;
  margin: 20px;
}
}

.evg__container__school--posts {
  display: flex;
  flex-direction: column;
}

.evg__schooly--butty {
  font-family: $headings--font-family;
  font-weight: $font-weight--semibold;
  margin: 20px 30px;

&:hover {
 background-color: $brand__primary--blue;
  border-radius: 0;
  margin: 20px 0 0 0 ;
}
}



/* --------------------------------------
SCHOOL POST CAROUSEL
-------------------------------------- */

.fl-post-carousel-grid {
  word-wrap: normal;
}

/* --------------------------------------
CARD LAYOUTS - TAKEN FORM SCHOOL POST GRID - UNNEEDED?
-------------------------------------- */

//.fl-post-grid {
//  display:flex;
//  flex-wrap:wrap;
//}
//
//.fl-post-column {
//  display: flex;
//  align-items: stretch;
//}



//.fl-post-grid-post {
//  font-size: 14px;
//}
//
//.fl-post-text {
//  padding: 20px;
//
//}
//
//.fl-post-title {
//  font-size: 20px;
//  line-height: 26px;
//}
//
//.fl-post-meta {
//  padding: 0 0 15px;
//
//}
//.fl-post-grid {
//  display:flex;
//  flex-wrap:wrap;
//
//}
//
//.fl-post-column {
//  display: flex;
//  align-items: stretch;
//
//
//}
//
//.evg__posts--item {
//  display: flex;
//  flex-direction: column;
//
//  padding-top: 20px;
//
//
//
//}
//
//.evg__posts--excerpt {
//  text-align: center;
//  font-size: 1rem;
//  padding: 10px;
//  margin:0;
//
//}
//
//.evg__posts--title {
//  text-align: center;
//  font-size: 1.4rem;
//  letter-spacing: .2rem;
//  padding: 10px;
//  margin:0;
//
//}
//
//
//.evg__container--posts{
//  display: flex;
//  flex-direction: column;
//  height: 440px;
//}
//
//.evg__posts--item {
//  justify-content: space-between;
//  height: 100%;
//}




