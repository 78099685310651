
/* Typographical Elements loading?
--------------------------------------------- */

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  //font-size: $html--font-size; /* 10px browser default */
}

body {
  background-color: $body--background-color;
  color: $body--font-color;
  font-family: $body--font-family;
  @include rem( font-size, $body--font-size );
  font-weight: $font-weight--regular;
  line-height: $line-height--normal;
  margin: 0;
  overflow-x: hidden;
}

button,
input:focus,
input[type="button"],
input[type="reset"],
input[type="submit"],
textarea:focus,
.button,
.gallery img {
  transition: all 0.2s ease-in-out;
}

a {
  color: $brand__primary--blue;
  font-weight: $font-weight--bold;
  text-decoration: underline;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  &:focus,
  &:hover {
    color: $brand__primary--blue__hover;
    text-decoration: none;
  }
}

button a {
  color: white;
  font-style: normal;
  &:hover {
    background-color: $brand__primary--blue__hover;
  color: $brand__primary--blue;
}
}

p {
  margin: 0 0 30px;
  padding: 0;
}

hr {
  border: none;
  border-collapse: collapse;
  border-top: 1px solid $lighter-gray;
  clear: both;
  margin: 30px 0;
}

b,
strong {
  font-weight: $font-weight--bold;
}
