/* Gallery
--------------------------------------------- */

.gallery {
  overflow: hidden;

  img {
    border: $border-width $border-style $border-color-lighter;
    height: auto;
    padding: 4px;

    &:focus,
    &:hover {
      border: $border-width $border-style $border-color-med-light;
      outline: none;
    }
  }
}

.gallery-item {
  float: left;
  margin: 0 0 30px;
  text-align: center;

  .gallery-columns-1 & {
    width: 100%;
  }

  .gallery-columns-2 & {
    width: 50%;
  }

  .gallery-columns-3 & {
    width: 33%;
  }

  .gallery-columns-4 & {
    width: 25%;
  }

  .gallery-columns-5 & {
    width: 20%;
  }

  .gallery-columns-6 & {
    width: 16.6666%;
  }

  .gallery-columns-7 & {
    width: 14.2857%;
  }

  .gallery-columns-8 & {
    width: 12.5%;
  }

  .gallery-columns-9 & {
    width: 11.1111%;
  }

  .gallery-columns-2 &:nth-child(2n+1),
  .gallery-columns-3 &:nth-child(3n+1),
  .gallery-columns-4 &:nth-child(4n+1),
  .gallery-columns-5 &:nth-child(5n+1),
  .gallery-columns-6 &:nth-child(6n+1),
  .gallery-columns-7 &:nth-child(7n+1),
  .gallery-columns-8 &:nth-child(8n+1),
  .gallery-columns-9 &:nth-child(9n+1) {
    clear: left;
  }
}