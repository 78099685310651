/* Genesis
--------------------------------------------- */

.after-entry,
.archive-description,
.author-box {
  margin-bottom: 40px;
}

.after-entry {
  box-shadow: 0 0 $box-shadow-blur-radius rgba(0, 0, 0, $box-shadow-opacity);
  padding: 20px 30px;

  @media only screen and (min-width: $standard-screen-width) {
    padding: 40px 60px;
  }

  .enews {

    @media only screen and (min-width: $standard-screen-width) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .widget:last-of-type {
    margin-bottom: 0;
  }
}

.author-box {

  @media only screen and (min-width: $standard-screen-width) {
    background-color: $author-box--background-color;
    border: $border-width $border-style $border-color-light;
    padding: 30px;
  }
}

.breadcrumb {
  border-bottom: 1px solid $lighter-gray;
  font-size: rem ( $after-entry__breadcrumb--font-size );
  margin-bottom: 40px;
  padding-bottom: 10px;

  .genesis-title-hidden & {
    margin-top: 40px;
  }
}

.archive-description p:last-child,
.author-box p:last-child {
  margin-bottom: 0;
}
