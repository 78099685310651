/* --------------------------
ICONS - CUSTOM STYLES
------------------------- */

.evg__purchase--online::before {
content: url(/wp-content/uploads/2020/06/streamline-icon-monitor-shopping-cart@20x20.svg);
  padding-right: 10px;
}

.evg__purchase--phone::before {
  content: url(/wp-content/uploads/2020/06/streamline-icon-smartphone-pay-dollar-1@20x20.svg);
  padding-right: 10px;
}

.evg__purchase--person::before {
  content: url(/wp-content/uploads/2020/06/streamline-icon-credit-card-give@20x20.svg);
  padding-right: 10px;
}

.evg__purchase--form::before {
  content: url(/wp-content/uploads/2020/06/streamline-icon-mailbox-in@20x20.svg);
  padding-right: 10px;
}

.evg__note--icon::before {
  content: url(/wp-content/uploads/2020/06/streamline-icon-messages-bubble-warning@20x20.svg);
  padding-right: 10px;
}

.evg__ticket--icon::before {
  content: url(/wp-content/uploads/2020/06/streamline-icon-ticket-1@20x20.svg);
  padding-right: 10px;
}

.evg__parking--icon::before {
  content: url(/wp-content/uploads/2020/06/streamline-icon-road-sign-parking@30x30.svg);
  padding-right: 10px;
}

.evg__question--icon::before {
  content: url(/wp-content/uploads/2020/07/streamline-icon-question-circle@20x20.svg);
  padding-right: 10px;
}

.evg__email--icon::before {
  content: url(/wp-content/uploads/2020/07/streamline-icon-mail-send-email@10x10.svg);
  padding-right: 10px;
}

.evg__phone--icon::before {
  content: url(/wp-content/uploads/2020/07/streamline-icon-phone-mobile-phone@10x10.svg);
  padding-right: 10px;
}

.evg__purchase--option__headings {
  font-family: $headings--font-family;
  font-weight: $font-weight--bold;
  text-transform: uppercase;
}