//
// Entry Title
//======================================================================

.entry-title {
  @include rem( font-size, $entry-title--font-size );
  margin-bottom: 10px;

  a {
    color: $entry-title__link--font-color;
    text-decoration: none;

    &:focus,
    &:hover {
      color: $entry-title__link--font-color-hover;
    }
  }
}
