/* Header Menu - Primary Navigation
--------------------------------------------- */

.nav-primary {
  clear: left;
  padding-bottom: 15px;
  padding-top: 15px;
  width: 100%;

  @media only screen and (min-width: $standard-screen-width) {
    clear: none;
    float: right;
    width: auto;
  }

  .genesis-nav-menu a {

    @media only screen and (min-width: $standard-screen-width) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}