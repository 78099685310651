/* Skip Links
---------------------------------------------------------------------------- */

.genesis-skip-link {
  margin: 0;

  .skip-link-hidden {
    display: none;
    visibility: hidden;
  }

  li {
    height: 0;
    list-style: none;
    width: 0;
  }
}

/* Display outline on focus */
:focus {
  color: $dark-gray;
  outline: #ccc solid 1px;
}