/* Objects
--------------------------------------------- */

embed,
iframe,
img,
object,
video,
.wp-caption {
  max-width: 100%;
}

img {
  height: auto;
  vertical-align: top;
}

figure {
  margin: 0;
}