/* --------------------------
HEADER CUSTOM STYLES -- FLEXBOX
------------------------- */


.evg__header--container {
  display: flex;
  flex-wrap: wrap;
  //object-fit: cover;
  margin-top: 0;
  margin-bottom: 0;
  align-content: center;
  justify-content: center;
  background-color: #f9f9f9;



  .evg__header--bottom--overlay {
    position: absolute;
    bottom: 0;
    z-index: 1000;
  }
}

.evg__header--items {
  display: flex;
  flex-grow: 1;
  //object-fit: cover;

  //background-color: $white;
  padding: 1em;
  margin: 1em;

}


.evg__header--items--pic {
  //display: flex;
  width: 25vw;
  flex-grow: 1;
  padding: 0;
  margin: 0px;

}


.evg__header--items--how {
  //display: flex;
  background-color: #F9F9F9;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0;
}



.evg__header--content--pic{
 //flex-grow: 2;
}

#evg__header--logo {
  width: 400px;
}

/* REPEATING PATTERN OVERLAYS */
.evg__pattern--overlay__stripes {
  width: 100%;
  height: 100px;
  background-image: url(/wp-content/uploads/2020/07/rb_pattern_stripey01.svg);
  background-repeat: repeat-x;
  z-index:1000;
  position: absolute;
  bottom: 0;
  opacity: 80%;
  margin-bottom:0;
}


.evg__scroll--pulse{
  animation: pulse 2s infinite;
  z-index: 9999;
}


@-webkit-keyframes pulse {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(0, 10px); }
  100% {
    transform: translate(0, 0); } }

@keyframes pulse {
  0% {
    transform: translate(0, 0); }
  50% {
    transform: translate(0, 10px); }
  100% {
    transform: translate(0, 0); } }

.evg__scroll--icon-01 {
  position: absolute;
  width: 32px;
  height: 32px;
  background-image: url(/wp-content/uploads/2020/12/streamline-icon-arrow-down-1@32x32.svg);
  z-index: 1001;
  bottom: 0;
  margin-left: 150%;
  margin-bottom: 180px;
  z-index: 9999;

}

.evg__scroll--icon-02 {
  position: absolute;
  width: 32px;
  height: 32px;
  background-image: url(/wp-content/uploads/2020/12/streamline-icon-arrow-down-1@32x32.svg);
  z-index: 1001;
  bottom: 0;
  margin-left: 150%;
  margin-bottom: 160px;
  z-index: 9999;

}

.evg__scroll--icon-left01 {
  position: absolute;
  width: 32px;
  height: 32px;
  background-image: url(/wp-content/uploads/2020/12/streamline-icon-arrow-down-1@32x32.svg);
  z-index: 1001;
  bottom: 0;
  margin-left: 75%;
  margin-bottom: 180px;
  z-index: 9999;

}

.evg__scroll--icon-left02 {
  position: absolute;
  width: 32px;
  height: 32px;
  background-image: url(/wp-content/uploads/2020/12/streamline-icon-arrow-down-1@32x32.svg);
  z-index: 1001;
  bottom: 0;
  margin-left: 75%;
  margin-bottom: 160px;
  z-index: 9999;

}

