.entry-header {
  .entry-meta {
    @include rem( font-size, $entry-meta__header--font-size );
    margin-bottom: 20px;
  }
}

.entry-comments-link::before {
  content: "\2014";
  margin: 0 6px 0 2px;
}