//
// Button Styles
//======================================

/* BB 'read more' link as button

a.fl-post-feed-more {
  background-color: $brand__primary--blue;
  color: $white !important;
  border-radius: 4px;
  padding: 10px;
}

a.fl-post-feed-more:hover {
  background-color: $brand__primary--blue__hover !important;
}

*/

.wp-block-button__link {
color: $white !important;
  background-color: $brand--primary--blue !important;
  font-family: $headings-font !important;
  letter-spacing: $tracking--xsmall;
  &:hover {
    color: $brand-_primary--blue !important;
    background-color: $brand__primary--blue__hover !important;
  }
}

.wp-block-button{
  color: $white !important;
  background-color: $brand--primary--blue !important;
  font-family: $headings-font !important;
  letter-spacing: $tracking--xsmall;
  &:hover {
    color: $brand-_primary--blue !important;
    background-color: $brand__primary--blue__hover !important;
  }
}