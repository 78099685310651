/* ---------------------------------------
UABB Custom Styles
 ---------------------------------------- */

.uabb-blog-post-content{
  align-self: center;
}

.uabb-tab-link:active {
  border:none;
}

// LOGOS OF SEASON SPONSORS ON ABOUT PAGE

.uabb-photo-gallery{
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  justify-content:center;
}

.uabb-photo-gallery-item{
  align-self: center;
  margin: 20px;
  max-width: 200px;
  min-width: 100px;
  flex-grow: 1;
}

.evg__sponsor__logo--wrapper{
  filter: grayscale(100%);
  opacity: .5;
}

.uabb-blog-post-content{
  box-shadow: none !important;
}

.uabb-sf-subheading {
  font-family: $headings--font-family;
  line-height: $line-height--small;
  margin: 0 0 20px;
  text-transform: uppercase;
  @include rem( font-size, $h6--font-size );
  font-weight: $font-weight--semibold;
  color: $brand__primary--blue__hover !important;
}

