//
// Base Colors
//=========================

$black: #000;
$dark-gray: #333;
$med-gray: #777;
$med-light-gray: #999;
$light-gray: #ddd;
$lighter-gray: #eee;
$lightest-gray: #f5f5f5;
$blue: #0073e5;
$white: #fff;
$transparent: transparent;

//
// Border styles
//=======================================

$border-width: 1px;
$border-style: solid;
$border-color-med-light: $med-light-gray;
$border-color-light: $light-gray;
$border-color-lighter: $lighter-gray;
$site-footer--border-color: $med-light-gray;

//
// Box Shadow
//===============================

$box-shadow-blur-radius: 20px;
$box-shadow-opacity: 0.05;