//
// TablePress table styles
//==============================

// table title
.tablepress-table-name {
  color: $brand__primary--blue;
  margin-top: 100px;
  margin-bottom: 10px;
}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 667px) {
  .tablepress-table-name {
    margin-top: 40px;
  }
}


.tablepress td {
  font-size: 1rem;
}

.tablepress .odd td {
  border-color: $brand__primary--blue;
  background-color: #f9f9f9 !important;
}

.tablepress .even td {
  border-color: $brand__primary--blue;
}

.tablepress .odd th {
  font-size: 1rem;
  font-family: $headings--font-family;
  color: $white;
  background-color: $brand__primary--blue;
  border-color: $brand__primary--blue;
}

.dataTables_info,
.tablepress-table-description {
  font-size: .8rem;
  font-style: italic;
  padding-bottom: 10px;
}
.dataTables_wrapper {
  padding-bottom: 50px;
  border-bottom:  4px dotted $brand__primary--blue;
}

hr.evg__dotted--separator {
  border-top: 4px dotted $brand__primary--blue;
}

// School Division body copy above tables - change to normal size

.tablepress-table-description-id-1,
.tablepress-table-description-id-2,
.tablepress-table-description-id-9,
.tablepress-table-description-id-10 {
  font-size: 1rem;
  font-style: normal;
}

// MARGIN FIX ABOVE MIDDLE DIVISION 1ST TABLE

.tablepress-table-name-id-5 {
  margin-top: 20px ;
}

// COLUMN FIXES FOR MOBILE - FOR MULTI-LINE CONTENT ISSUE


@media only screen
and (max-width: 768px)
{
  #tablepress-5 thead{
    background-color: #63C4D0;
    height: 266px;
  }

  #tablepress-5 thead .column-5 {
    margin-top: 50px;
  }
}

@media only screen
and (max-width: 768px)
{
  #tablepress-6 thead{
    background-color: #63C4D0;
    height: 318px;
  }

  #tablepress-6 thead .column-5 {
    margin-top: 102px;
  }
}

@media only screen
and (max-width: 768px)
{
  #tablepress-7 thead{
    background-color: #63C4D0;
    height: 318px;
  }

  #tablepress-7 thead .column-5 {
    margin-top: 102px;
  }
}

// LOWER I TABLE - HYBRID CLASS
@media only screen
and (max-width: 768px)
{
  #tablepress-1 thead{
    background-color: #63C4D0;
    height: 240px;
  }

  #tablepress-1 thead .column-5 {
    margin-top: 24px;
  }
}

// LOWER I TABLE - VIRTUAL CLASS
@media only screen
and (max-width: 768px)
{
  #tablepress-21 thead{
    background-color: #63C4D0;
    height: 240px;
  }

  #tablepress-21 thead .column-5 {
    margin-top: 24px;
  }
}

// LOWER II TABLE
@media only screen
and (max-width: 768px)
{
  #tablepress-2 thead{
    background-color: #63C4D0;
    height: 240px;
  }

  #tablepress-2 thead .column-5 {
    margin-top: 24px;
  }
}

// LOWER III TABLE - HYBRID CLASS
@media only screen
and (max-width: 768px)
{
  #tablepress-3 thead{
    background-color: #63C4D0;
    height: 240px;
  }

  #tablepress-3 thead .column-5 {
    margin-top: 24px;
  }
}
