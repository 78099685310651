/*!
Theme Name: Balletomane
Theme URI: https://demo.studiopress.com/
Description: Genesis Child-Theme for Richmond Ballet
Author: EVERGIB
Author URI: https://evergib.com

Version: 1.0.0

Tags: accessibility-ready, block-styles, custom-colors, custom-logo, custom-menu, editor-style, featured-images, footer-widgets, full-width-template, left-sidebar, one-column, right-sidebar, rtl-language-support, sticky-post, theme-options, threaded-comments, translation-ready, two-columns, wide-blocks

Template: genesis

License: GPL-2.0-or-later
License URI: https://www.gnu.org/licenses/gpl-2.0.html

Text Domain: balletomane
*/

@import "bourbon";
@import "neat";
@import "../../node_modules/sass-rem/rem";
@import "utilities/index";
@import "base/index";
@import "layouts/index";
@import "common-classes/index";
@import "components/index";
// @import "views/index";
@import "plugins/index";
@import "print-styles/index";
@import "custom/index";