/* WordPress
--------------------------------------------- */

a.aligncenter img {
  display: block;
  margin: 0 auto;
}

a.alignnone {
  display: inline-block;
}

.alignleft {
  float: left;
  text-align: left;
}

.alignright {
  float: right;
  text-align: right;
}

a.alignleft,
a.alignnone,
a.alignright {
  max-width: 100%;
}

img.centered,
.aligncenter,
.singular-image {
  display: block;
  margin: 0 auto 30px;
}

img.alignnone,
.alignnone {
  margin-bottom: 15px;
}

a.alignleft,
img.alignleft,
.wp-caption.alignleft {
  margin: 0 20px 20px 0;
}

a.alignright,
img.alignright,
.wp-caption.alignright {
  margin: 0 0 20px 20px;
}

.gallery-caption,
.wp-caption-text {
  @include rem( font-size, $entry-content__caption--font-size );
  font-weight: $font-weight--semibold;
  margin: 0;
  text-align: center;
}

.entry-content p.wp-caption-text {
  margin-bottom: 0;
}

.entry-content .wp-audio-shortcode,
.entry-content .wp-playlist,
.entry-content .wp-video {
  margin: 0 0 30px;
}
