/* -------------------------------
// ROWS AND COLUMNS CUSTOM STYLES
--------------------------------- */

// LIGHT GRAY BACKGROUND COLOR FOR ROWS
.evg__background--color__light {
  background-color: $lightest-gray;
}

// SEPARATOR LINE
.fl-separator {
border-top: 2px solid $brand__primary--blue;
}

.evg__heading--background {
  background-color: $lightest-gray;
  text-align: center;
  vertical-align: middle;

}

// SCROLLING BOX FOR BIO OVERFLOW TEXT
.evg__text--overflow{
  min-height: 20vh;
  max-height: 40vh;
  overflow: auto;
  margin-bottom: 40px;
}

// QUOTES
.evg__quote--box {
  display: flex;
  flex-wrap:wrap;
}

.evg__quote--item {
  display:flex;
  flex-grow:1;
  background-color: $lightest-gray;
  margin: 10px;
  padding: 40px;
  justify-content: center;
  align-items: center;
}

.evg__quote--cite {
}

// FLEXBOX SKINNY HEADERS FOR SITE SECTIONS

.evg__header--bar {
  width:75vw;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction:row;
  align-items: center;
  align-content: center;
  padding: 40px;
  color: white;
  background-color: $brand__primary--blue;
}
@media all and (max-width: 960px) {
  .evg__header--bar {
    width: 100vw;
  }
}

.evg__header--bar__icon--container{
  display:flex;
  justify-content: center;
  height: 100px;
  width: 25%;
  flex-grow: 1;
}

@media all and (max-width: 960px){
  .evg__header--bar__icon--container{
    justify-content: flex-start;
    padding-bottom: 40px;
  }

  @media only screen
  and (min-device-width: 1180px) {
    .evg__header--bar__icon--container{}
      justify-content: flex-start;
    }

}

.evg__header--bar__icon {
  width: 100px;
  fill: $white;
}

.evg__header--bar__text--container{

  flex-grow: 1;
}

.evg__header--bar__title{
  margin: 0;
}

@media only screen and (max-width: 668px) {

  .evg__header--bar__title {
    font-size: 2.75rem !important;
    letter-spacing: 1px;
  }
}

.evg__header--bar__subtext{
  margin: 0;
}

// UNDERLINE HIGHLIGHT OF KEY WORD
.evg__header--bar__subtext--underline {
  border-bottom: 2px solid $white;
  font-size: 15px;
  vertical-align: top;
 // line-height: normal;
}

.evg__subscription--list {
  background-color: blue;
}

// Separator Line

.wp-block-separator.is-style-wide {
  border-bottom: 2px solid $light-gray;
}

// SPACER

.evg__spacer {
  height: 50px;
}

/* -----------------------------------------

STICKY STUFF

------------------------------------------ */


@media only screen
and (min-device-width: 760px) {
  .evg__sticky {
    position: sticky;
    top: 100px;

  }
}

.evg__sticky--row {
  position: sticky;
  top: 68px;
  z-index: 1000;
  background-color: #ffffff;
  margin-left: -300px;
  margin-right: -300px;
}

//@media all and (min--moz-device-pixel-ratio:0) and (min-resolution: 3e1dpcm) {
//
//  .evg__sticky--row {
//    top: 100px;
//  }
//}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 667px)
{
  .evg__sticky--row {
    position: sticky;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
}


