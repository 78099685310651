/* -------------------------------
CUSTOM MENU AND NAV STYLES
-------------------------------- */
.genesis-nav-menu a:hover{
  color: $brand__primary--blue__hover !important;
}

.sub-menu a {
  font-size: .8rem !important;

  :hover{
    color: $brand__primary--blue__hover;
    transition: ease .2s;
  }
}

/* --------------------------------------
MENU AND SUBMENUS MAIN NAV
-------------------------------------- */

// PUSH 'COMMUNITY' SUB-MENU LEFT TO MAKE ROOM FOR SUB-SUB-MENU

@media only screen
and (min-width: 668px)
{

  #menu-item-3125 .menu-item {
    position: relative;
    left: -50px;
  }

  // PUSH 'COMMUNITY' SUB-SUB-MENU TO THE RIGHT
  #menu-item-2629{
    margin-left: 50px;
  }
  #menu-item-2563{
    margin-left: 50px;
  }

  #menu-item-3126{
    margin-left: 50px;
  }

  // PUSH 'ABOUT' SUB-MENU LEFT SO DOESN'T GO OFF-SCREEN TO RIGHT
  #menu-item-2883 .sub-menu {

    margin-left: -50px;
  }


}


/* ------------------------------
TOP NAV BAR
------------------------------- */

.evg__topnav--container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
}


.evg__topnav--item {
  margin-left: 20px;
}

.evg__button--donation {
  border-radius: 0;
  margin-left: 0;
  background-color: $brand__primary--blue;
  color: #004D66;
  &:hover {
    background-color: $brand__primary--blue__hover;
    color: $brand__primary--blue;
  }
}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 768px) {

  .evg__topnav--container{
    height: 40px;
    overflow: hidden;
  }

  .evg__button--donation {
    font-size: .8rem;
    align-content: cener;
    padding: 10px;
    height: 50px;
  }
}

