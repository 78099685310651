/* ----------------------
// PERFORMANCE POSTS - CUSTOM STYLES
----------------------- */

h2.evg__post--title {
  letter-spacing: $tracking--normal;
  @include rem( font-size, $event__performance__post--title );
  text-align: center;
  color: white;

}

.fl-post-feed-more {
  background-color: $brand__primary--blue;
  color: white;
  padding: 10px;
  border-radius: 4px;
  width: 200px;
  text-align: center;
  font-family: $base-font;
  font-weight: $font-weight--black;
  letter-spacing: $tracking--normal;
  transition: 0.4s;
  margin-bottom: 40px;

  &:hover{
    background-color: #0d374f;
  }
}

.evg__performances--title {
  font-size: 2rem;
  color: $black;
}

a.tribe-events-calendar-list__event-title-link {
  font-family: $headings--font-family;
  text-transform: uppercase;
  color: $brand__primary--blue !important;
  letter-spacing: $tracking--normal;
}


a.tribe-events-calendar-month__calendar-event-title-link {
  font-family: $headings--font-family;
  text-transform: uppercase;
  color: $brand__primary--blue !important;
  letter-spacing: $tracking--small;
  font-weight: $font-weight--black;
}

a.tribe-events-calendar-month__calendar-event-tooltip-title-link {
  font-family: $headings--font-family;
  text-transform: uppercase;
  color: $brand__primary--blue !important;
  letter-spacing: $tracking--small;
}

.tribe-events-single-event-title {
  color: $brand__primary--blue;
}

.tribe-events-c-search__button {
  background-color: $brand__primary--blue !important;
  text-transform: uppercase;
  letter-spacing: $tracking--small;
}

.tribe-events-calendar-month__calendar-event{
  background-color: $lightest-gray;
}

.evg__button--performance {
  background-color: $brand__primary--blue;
  color: $white !important;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;

  &:hover {
    background-color: $brand__primary--blue__hover;
  }
}



