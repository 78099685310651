/* --------------------------
FOOTER CUSTOM STYLES
------------------------- */

a#evg__link--dark {
  color: #333333;
}

.evg__mousetype {
  font-size: .75rem;
}