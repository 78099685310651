//
// TYPOGRAPHY - CUSTOM STYLES
//================================================

.heading__color--white {
  color: $white;
}

.evg-company-title {
  color:$brand__primary--blue;
}

.evg__dancer--name {
  font-size: $h2--font-size;
  text-align: center;
  text-transform: uppercase;
  color:$brand__primary--blue !important;
}

.evg__dancer--name__post {
  @include rem( font-size, $body--font-size );
  color: $brand__primary--blue;
  text-transform: uppercase;
  letter-spacing: .25rem;
  padding: 15px 0 15px 0;
}

.evg__dancer--quote {
  font-size: .85rem !important;
  color: $black !important;
}

.evg__dancer--meta {
  text-align: center;
  color: $black !important;
  padding: 0 !important;
}

evg__dancer--infobox {
  background-color: $brand__primary--blue !important;
}

.evg__text--block__grey {
  background-color: $lightest-gray;
  padding: 20px;
}

.mousetype {
  font-size: $mousetype;
}

// List items ul/li
.evg__list--items li{
  margin: 10px 0 10px 0;
}

.evg__heading--blue {
  color: $brand__primary--blue;
 // &::before{
 //   content: url("http://rva-ballet.local/wp-content/uploads/2020/03/RB_Icon_K_export.svg");
  //  padding-right: 10px;
 // }
}

.evg__company--blurb{


}

.evg__button--padder {
  margin-top: 10px;
}
