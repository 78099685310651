/* WP Forms
--------------------------------------------- */

.entry-content .wpforms-container {
  margin-bottom: 40px;
}

.entry-content .wpforms-form .wpforms-field {
  clear: both;
  margin: 20px 0;
  overflow: hidden;
}

.site-container .wpforms-container .wpforms-form .wpforms-field input {
  border-radius: 0;
  height: auto;
  padding: 15px;
}

.site-container .entry-content .wpforms-form .wpforms-field-label {
  font-weight: $font-weight--semibold;
}

.site-container .entry-content .wpforms-form .wpforms-field-sublabel {
  @include rem( font-size, $wpforms-widget__field-sublabel--font-size );
  font-weight: $font-weight--light;
}

.entry-content .wpforms-form .wpforms-field-hp {
  display: none !important;
  left: -9000px !important;
  position: absolute !important;
}

.site-container .entry-content .wpforms-form textarea {
  padding: 15px;
}

.site-container .entry-content .wpforms-form textarea.wpforms-field-small {
  height: 120px;
}

.site-container .entry-content .wpforms-form textarea.wpforms-field-medium {
  height: 200px;
}

.site-container .entry-content .wpforms-form textarea.wpforms-field-large {
  height: 300px;
}
