/* ---------------------------------------
CARDS - FLEXBOX CARD CUSTOM STYLES
-----------------------------------------*/

.evg__container--posts{
  display: flex;
  flex-direction: column;
  height: 460px;
}
// THIS WAS IN SUBSCRIPTIONS/ALT THEMER LAYOUT? SHORTER CARDS THAN ABOVE CODE
//.evg__container--posts{
//  display: flex;
//  flex-direction: column;
//  height: 380px;
//}

.evg__posts--item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 20px;
}

.evg__posts--excerpt {
  text-align: center;
  font-size: 1rem;
  padding: 10px;
  margin:0;
}

.evg__posts--title {
  text-align: center;
  font-size: 1.4rem;
  letter-spacing: .2rem;
  padding: 10px;
  margin:0;
}

.evg__posts--title a {
  color: $brand__primary--blue;
}

// CARDS - ADULT CLASSES, HEIGHT FIX
//.evg__cards--adult-classes {
//  display: flex;
//  flex-direction: column;
//  height: 460px;
//}



// CARDS - CLUB 407 VENUE/EVENT CARDS

.evg__club407__venue--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

.evg__club407__venue--items {
  justify-content: space-between;
  margin: 20px;
  background-color: #ffffff;
  width: 400px;
  padding: 0px;
}

.evg__club407__card--header {
}

.evg__club407__card--text {
  padding: 20px;
}

.evg__club407__card-image {
  margin:0;
  padding:0;
}

.evg__club407__venue--items ul li {
  list-style-type: circle;
  padding: 4px;
}

.evg__button--box {
  margin-top: 40px;
  bottom: 0;
}


// CARDS - CONTACT US PAGE ROLODEX

.evg__contact--wrapper {
  display: flex;
}

// This makes the rolodex photos smaller and flexible inside
.evg__rolodex--photo img{
  height: 250px;
  width: 250px;
  object-fit: cover;

}

.evg__contact--text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

}

// THIS IS KINDA UNIVERSAL CSS FOR CARDS FROM THE BB PLUGIN...

.fl-post-grid-post {
  font-size: 14px;
}
.fl-post-text {
  padding: 20px;
}
.fl-post-title {
  font-size: 20px;
  line-height: 26px;
  margin: 0;
  padding: 0 0 5px;
}
.fl-post-meta {
  padding: 0 0 15px;
}


