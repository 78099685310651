/* Column Classes
--------------------------------------------- */

.five-sixths,
.four-sixths,
.one-fourth,
.one-half,
.one-sixth,
.one-third,
.three-fourths,
.three-sixths,
.two-fourths,
.two-sixths,
.two-thirds {

  @media only screen and (min-width: $standard-screen-width) {
    float: left;
    margin-left: 2.564102564102564%;
  }
}

.one-half,
.three-sixths,
.two-fourths {

  @media only screen and (min-width: $standard-screen-width) {
    width: 48.717948717948715%;
  }
}

.one-third,
.two-sixths {

  @media only screen and (min-width: $standard-screen-width) {
    width: 31.623931623931625%;
  }
}

.four-sixths,
.two-thirds {

  @media only screen and (min-width: $standard-screen-width) {
    width: 65.81196581196582%;
  }
}

.one-fourth {

  @media only screen and (min-width: $standard-screen-width) {
    width: 23.076923076923077%;
  }
}

.three-fourths {

  @media only screen and (min-width: $standard-screen-width) {
    width: 74.35897435897436%;
  }
}

.one-sixth {

  @media only screen and (min-width: $standard-screen-width) {
    width: 14.52991452991453%;
  }
}

.five-sixths {

  @media only screen and (min-width: $standard-screen-width) {
    width: 82.90598290598291%;
  }
}

.first {

  @media only screen and (min-width: $standard-screen-width) {
    clear: both;
    margin-left: 0;
  }
}
