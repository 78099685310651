/*---------------------------------------------
CONTACT US PAGE
---------------------------------------------- */

.evg__contact-numbers__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
}

.evg__contact-numbers__item {
  padding: 20px;
  margin: 4px;
  background-color: white;
  flex-grow: 1;
}

/* -------------------------------------
CONTACT US PAGE FLEXBOX INFO UNIT
-------------------------------------- */

.evg__contact-numbers__container{
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.evg__contact-numbers__item {
  transition: background-color 1s ease;
  background-color: $white;
  margin: 4px;
  padding: 20px;
  flex-grow: 1;
}

.evg__contact-numbers__item:hover{
  background-color: $lighter-gray;
}