/* Entry Content
--------------------------------------------- */

.entry {
  margin-bottom: 40px;
}

.entry-content {

  ol,
  ul {
    margin-bottom: 30px;
    padding-left: 40px;
  }

  ol > li {
    list-style-type: decimal;
  }

  ul > li {
    list-style-type: disc;
  }

  ol ul > li,
  ul ul > li {
    list-style-type: circle;
  }

  ol ol,
  ul ul {
    margin-bottom: 0;
  }

  code {
    background-color: $code--background-color;
  }
}

.content .sticky {
  background-color: $sticky--background-color;
  padding: 30px;
}