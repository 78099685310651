//---------------------
// CUSTOM BUTTON STYLES
//----------------------

// STANDARD BUTTON

.evg__standard--button {

  color: $white;
  text-transform: uppercase;
  font-family: $headings-font;
  font-weight: $font-weight--bold;
  font-size: 16px;
  transition: all 0.5s ease;
  background-color: $brand__primary--blue;
  padding: 20px;
  border-radius: 4px;
  margin: 10px;
  letter-spacing: 1.4px;
}

.evg__standard--button:hover {

  background-color: $brand__primary--blue__hover;
  color: $brand__primary--blue;
}

// DOUBLE BUTTON FLEXBOX

.evg__button-group--container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.evg__button-group--item {

  display: flex;
  flex-wrap: wrap;
  padding: 20px;

}

// POST GRID CARD BUTTONS

.evg__post--button {
  text-align:center;
  color: $white;
  text-transform: uppercase;
  font-family: $headings-font;
  font-weight: $font-weight--bold;
  font-size: 16px;
  transition: all 0.5s ease;
  background-color: $brand__primary--blue;
  padding: 10px;
  border-radius: 4px;
  margin: 20px;
  letter-spacing: 1.4px;
}

.evg__post--button:hover {
  background-color: #005670;
  color: #63C4D0;
  margin-left: 0;
  margin-right:0;
  border-radius: 0px;
}