//
// Entry Meta
//=====================================================================

.entry-footer {
  .entry-meta {
    border-top: 1px solid $lighter-gray;
    padding-top: 20px;
    @include rem( font-size, $entry-meta__footer--font-size );
    margin-bottom: 0px;
  }
}

.entry-categories,
.entry-tags {
  display: block;
}
