/* Pagination
--------------------------------------------- */

.pagination {
  clear: both;
  margin: 60px 0;
}

.adjacent-entry-pagination {
  margin-bottom: 0;
}

.archive-pagination li {
    display: inline;

  a {
    background-color: $archive-pagination--background-color;
    color: $archive-pagination--font-color;
    cursor: pointer;
    display: inline-block;
    @include rem( font-size, $archive-pagination--font-size );
    font-weight: $font-weight--semibold;
    margin-bottom: 4px;
    padding: 8px 12px;
    text-decoration: none;
  }

  a:focus,
  a:hover,
  &.active a {
    background-color: $archive-pagination--background-color-hover;
    color: $archive-pagination--font-color-hover;
  }
}